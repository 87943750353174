import { AnswerPathTreeNode } from '@breathelife/questionnaire-engine';

import { NodeIds } from '../../nodeIds';

export const otherLifeInsurancePolicies: AnswerPathTreeNode = {
  path: 'otherLifeInsurancePolicies',
  children: [
    { path: 'exists', nodeId: NodeIds.otherLifeInsurancePoliciesExists },
    {
      path: 'existingPolicies',
      children: [
        { path: 'coverageAmount', nodeId: NodeIds.otherLifeInsurancePoliciesCoverageAmount },
        { path: 'willBeReplaced', nodeId: NodeIds.otherLifeInsurancePolicesWillBeReplaced },
        { path: 'willVoid', nodeId: NodeIds.otherLifeInsurancePoliciesWillBeVoided },
        { path: 'policyNumber', nodeId: NodeIds.otherLifeInsurancePolicyNumber },
        {
          path: 'individual',
          children: [
            { path: 'exists', nodeId: NodeIds.otherIndividualLifeInsurancePoliciesInForce },
            { path: 'coverageAmount', nodeId: NodeIds.otherIndividualLifeInsurancePoliciesCoverageAmount },
            { path: 'exceedsMaximum', nodeId: NodeIds.existingCoverageExceedsMaximum },
            {
              path: 'withCarrier',
              children: [
                { path: 'inForce', nodeId: NodeIds.otherIndividualLifeInsurancePoliciesInForceWithCarrier },
                {
                  path: 'coverageAmount',
                  nodeId: NodeIds.otherIndividualLifeInsurancePoliciesInForceWithCarrierCoverage,
                },
                { path: 'exceedsMaximum', nodeId: NodeIds.existingCarrierCoverageExceedsMaximum },
              ],
            },
          ],
        },
      ],
    },
    { path: '90Days', nodeId: 'other-life-insurance-applied-within-90-days' },
    { path: 'pending', nodeId: NodeIds.otherLifeInsuranceHasPendingReplacementInsurance },
    { path: '1035Exchange', nodeId: 'other-life-insurance-will-replacement-be-section-1035-exchange' },
    { path: 'externalOrInternalForm', nodeId: NodeIds.externalOrInternalForm },
    {
      path: 'replacementInsurance',
      nodeId: NodeIds.replacementInsurance,
      isCollection: true,
      children: [
        { path: 'choices', nodeId: NodeIds.replacementInsuranceChoices },
        { path: 'companyName', nodeId: NodeIds.replacementInsuranceCompanyName },
        { path: 'policyNumber', nodeId: NodeIds.replacementInsurancePolicyNumber },
        { path: 'issueDate', nodeId: NodeIds.replacementInsuranceIssueDate },
        { path: 'coverageAmount', nodeId: NodeIds.replacementInsuranceCoverageAmount },
        { path: 'planType', nodeId: NodeIds.replacementInsurancePlanType },
        { path: 'businessOrPersonal', nodeId: NodeIds.replacementInsuranceBusinessOrPersonal },
        { path: 'accidentalDeath', nodeId: NodeIds.replacementInsuranceAccidentalDeath },
        { path: 'pending', nodeId: NodeIds.replacementInsurancePending },
        { path: 'replacing', nodeId: NodeIds.replacementInsuranceReplacing },
        { path: 'companyNameOther', nodeId: NodeIds.replacementInsuranceCompanyNameOther },
        { path: 'invalidityMonthlyBenefit', nodeId: NodeIds.replacementInsuranceInvalidityMonthlyBenefit },
        { path: 'invalidityType', nodeId: NodeIds.replacementInsuranceInvalidityType },
        {
          path: 'invalidityAccidentEliminationPeriod',
          nodeId: NodeIds.replacementInsuranceInvalidityAccidentEliminationPeriod,
        },
        {
          path: 'invalidityAccidentBenefitPeriod',
          nodeId: NodeIds.replacementInsuranceInvalidityAccidentBenefitPeriod,
        },
        {
          path: 'invaliditySicknessEliminationPeriod',
          nodeId: NodeIds.replacementInsuranceInvaliditySicknessEliminationPeriod,
        },
        {
          path: 'invaliditySicknessBenefitPeriod',
          nodeId: NodeIds.replacementInsuranceInvaliditySicknessBenefitPeriod,
        },
      ],
    },
  ],
};
